<template>
  <div class="group">
    <el-header class="group-header">
      <div class="home-title">
        <img src="../../assets/img/logo.png" alt="" width="38px" height="38px">
        <span>贸易广场</span>
      </div>
    </el-header>
    <div class="group-wrap">
      <div class="camera-monitor">
        <div class="camera-box" ref="cameraBox">
          <!-- <EZUIKit class="camera" :width="1000" :height="600" :videoUrl="streamList" ref="monitor" :splitBasis="9" /> -->
          <HikVision ref="hik" :width="width" :height="height" :pointCode="cameraCode" :layout="layout" v-if="show" :info="cameraInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HikVision from '../../components/HikVision.vue'
export default {
  components: {
    HikVision
  },
  data () {
    return {
      cameraCode: '',
      cameraInfo: {},
      height: 0,
      width: 0,
      unitId: 0,
      layout: '3x3',
      show: false
    }
  },
  created () {
    this.initCameraSource()
  },
  methods: {
    // 查看视频
    async lookVideo () {
      const { data: result } = await this.$axios.get('/cameraUnitSpecial/list', {
        params: { unitId: this.$route.query.unitId }
      })
      if (result.code !== 200) return this.$message.error(result.msg)

      const array = []
      for (let i = 0; i < result.data.length; i++) {
        array.push(result.data[i].cameraCode)
      }

      this.cameraCode = array.join(',')

      this.$nextTick(() => {
        if (this.$route.query.unitId === 13077) {
          this.layout = '3x3'
        } else {
          this.layout = '2x2'
        }
        this.width = this.$refs.cameraBox.offsetWidth
        this.height = this.$refs.cameraBox.offsetHeight
        this.show = true
      })
    },
    // 获取海康威视服务器配置
    async initCameraSource () {
      if (!this.$route.query.areaCode || !this.$route.query.unitId) {
        this.$message.error('无法获取摄像头参数配置')
        return
      }
      const { data: result } = await this.$axios.get('/cameraParams/list', { params: { areaCode: this.$route.query.areaCode } })
      if (result.code === 200) {
        this.cameraInfo = result.data[0]
        this.lookVideo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  .group-header {
    color: #39b8e6;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 76px !important;
    font-size: 18px;
    padding: 0 1%;
    background: url("../../assets/img/bg-header.png") no-repeat center center;
    background-size: 100% 100%;
    .home-title {
      height: 100%;
      width: 20%;
      padding: 0 0.1%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      color: #fff;
      span {
        letter-spacing: 2px;
      }
    }
  }
  .group-wrap {
    height: calc(100vh - 76px);
    width: 100%;
    display: flex;
    padding: 0 2% 2% 2%;
    box-sizing: border-box;
    background: url("../../assets/img/bg-body.png") no-repeat center center;
    background-size: 100% 100%;
    .camera-monitor {
      width: 100%;
      height: 100%;
      .camera-box {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
</style>
